import axios from 'axios'
import {saveAs} from 'file-saver'
import {getToken} from '@/utils/auth'

export function download(fileName, showName) {
    let url = process.env.VUE_APP_BASE_API + "/common/download?fileName=" + encodeURI(fileName) + "&delete=true"
    let fileType = fileName.substr(fileName.lastIndexOf('.'))
    axios({
        method: 'get',
        url: url,
        responseType: 'blob',
        headers: {'Authorization': 'Bearer ' + getToken()}
    }).then(async (res) => {
        const isLogin = await blobValidate(res.data)
        if (isLogin) {
            const blob = new Blob([res.data])
            saveAs(blob, showName + fileType)
        } else {
            return Promise.reject(res.data)
        }
    })
}

// 验证是否为blob格式
async function blobValidate(data) {
    try {
        const text = await data.text();
        JSON.parse(text);
        return false;
    } catch (error) {
        return true;
    }
}
