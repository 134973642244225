<template>
    <div id="status" class="status">
        <Progress v-bind:step="step"/>
        <div class="status-main">
            <i class="icon-status"
               v-bind:class="{'el-icon-warning':status==='1','el-icon-info':status==='2','el-icon-error':status==='3','el-icon-success':status==='4'}"></i>
            <div v-if="status ==='1'" class="status-info">
                您的简历尚未提交，请尽快完善！
            </div>
            <div v-else-if="status === '2'" class="status-info">
                您的简历在审核中，请您耐心等待！
            </div>
            <div v-else-if="status === '3'" class="status-info">
                抱歉，审核失败！
            </div>
            <div v-else-if="status === '4' || status  ==='5'" class="status-info">
                恭喜您，审核通过！
            </div>
            <div v-if="status !=='1'" class="status-msg">
                姓名：{{ resume.name }} 联系电话：{{ resume.phonenumber }}
            </div>
            <div class="status-tip">请妥善保管您的账户信息</div>
            <div class="btn-group">
                <el-button v-if="btnShowResult" type="primary" style="width: 120px" @click="showFailReason">
                    查看失败原因
                </el-button>
                <el-button v-if="btnToResumeEnabled" type="primary" style="width: 120px" @click="toResume">
                    完善简历内容
                </el-button>
                <el-button v-if="btnDownloadResume" type="primary" style="width: 120px" @click="downloadResume">下载报名表
                </el-button>
                <el-button @click="back" style="width: 120px">返回</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {downloadUserResume} from '@/api/User'
import {download} from "@/utils/download";

export default {
    name: "Status",
    data() {
        return {
            step: null,
            status: null,
            btnShowResult: false,
            btnDownloadResume: false,
            btnToResumeEnabled: false,
            resume: null
        }
    },
    created() {
        // 判断当前登录用户的简历填报状态
        let resume = sessionStorage.getItem("resume")
        if (resume) {
            let resumeObj = JSON.parse(resume)
            this.resume = resumeObj
            if (resumeObj.status === '1') {
                this.step = 1
                this.status = '1'
                this.btnToResumeEnabled = true
            } else if (resumeObj.status === '2') {
                this.step = 2
                this.status = '2'
            } else if (resumeObj.status === '3') {
                this.step = 3
                this.status = '3'
                this.btnShowResult = true
            } else if (resumeObj.status === '4') {
                this.step = 3
                this.status = '4'
                this.btnDownloadResume = true
            } else if (resumeObj.status === '5') {
                this.step = 3
                this.status = '5'
                this.btnDownloadResume = true
            } else {
                this.step = 1
                this.status = '1'
                this.btnToResumeEnabled = true
            }
        }
    },
    methods: {
        back() {
            history.back()
        },
        showFailReason() {
            this.$confirm(this.resume.refuseReason, '驳回原因', {
                confirmButtonText: '编辑简历',
                cancelButtonText: '取消编辑',
                center: true,
                type: 'error'
            }).then(() => {
                this.$router.push({path: "/user_resume"});
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消编辑'
                });
            })
        },
        toResume() {
            this.$router.push({path: "/user_resume"});
        },
        downloadResume() {
            downloadUserResume().then(response => {
                if (response.status === 200 && response.data.code === 200) {
                    download(response.data.data.fileName, '辽科馆-' + this.resume.name)
                }
            })
        }
    }
}
</script>

<style scoped>
#status {
    margin: 0 auto;
    max-width: 1440px;
}

#status > .status-main {
    max-width: 1380px;
    background-color: #ffffff;
    margin-top: 32px;
    padding: 0 30px 0 30px;
    height: 593px;
    text-align: center;
}

#status .icon-status {
    font-size: 64px;
    margin-top: 64px;
}

#status .el-icon-info, #status .el-icon-success {
    color: #1677FF;
}

#status .el-icon-warning, #status .el-icon-error {
    color: #FF3B30;
}

#status .status-info {
    margin-top: 25px;
    font-size: 20px;
    font-weight: 600;
    color: #333333;
}

#status .status-msg {
    margin-top: 25px;
    font-size: 14px;
    font-weight: 500;
    color: #333333;
}

#status .status-tip {
    color: #B4B4B4;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
}

#status .btn-group {
    margin-top: 24px;
}
</style>
